import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Flip } from "gsap/Flip";
// import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Observer } from "gsap/Observer";
// import { ScrollToPlugin } from "gsap/ScrollToPlugin";
// import { Draggable } from "gsap/Draggable";
// import { MotionPathPlugin } from "gsap/MotionPathPlugin";
// import { CustomEase } from "gsap/CustomEase";
// import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
// import { ScrollSmoother } from "gsap/ScrollSmoother";
// import { GSDevTools } from "gsap/GSDevTools";
// import { InertiaPlugin } from "gsap/InertiaPlugin";
// import { MorphSVGPlugin } from "gsap/MorphSVGPlugin";
// import { MotionPathHelper } from "gsap/MotionPathHelper";
// import { SplitText } from "gsap/SplitText";
// import { CustomBounce } from "gsap/CustomBounce";
// import { CustomWiggle } from "gsap/CustomWiggle";
//------------------------------------------------------//
// Setup 🧦 GSAP and register 📜 ScrollTrigger
// Register more plugins like so: gsap.registerPlugin(ScrollTrigger, splitText);
// Go to https://greensock.com/docs/v3/Installation?checked=core,scrollTrigger,splitText#installer
//------------------------------------------------------//
// gsap.registerPlugin(Flip, ScrollTrigger, Observer, ScrollToPlugin, Draggable, MotionPathPlugin, CustomEase, DrawSVGPlugin, ScrollSmoother, GSDevTools, InertiaPlugin, MorphSVGPlugin, MotionPathHelper, SplitText, CustomBounce, CustomWiggle);
gsap.registerPlugin(ScrollTrigger);

ScrollTrigger.defaults({
  toggleActions: "restart pause resume pause",
  markers: window.location.hostname.endsWith(".local") ? true : false,
});

// END Setup 🧦 GSAP -------------------------------------//

//--------------------------------//
// ⭐ Kenmerken
//--------------------------------//
console.clear(); // Start with a clean console on refesh
//------------------------------------------------------//
// Animation ELEMENT
//------------------------------------------------------//
document.querySelectorAll(".ACF-kenmerken").forEach(function (container) {
  const q = gsap.utils.selector(container); // scope to current
  const move = 100;
  const duration = 1;
  // const morph = gsap.utils.wrap(q(".morph"));
  // const text = q(".text > *");
  // const textWrap = gsap.utils.wrap(text);
  const bg = q("li");
  const bgWrap = gsap.utils.wrap(bg);
  //--------------------------------//
  // Setup
  //--------------------------------//
  // gsap.set(bg, {
  //   xPercent: -move
  // });
  gsap.set(bg[3], {
    xPercent: -move,
  });
  gsap.set(bg[0], {
    xPercent: 0,
  });
  gsap.set(bg[1], {
    xPercent: move,
  });
  gsap.set(bg[2], {
    xPercent: move * 2,
  });

  // END Setup --------------//

  const tl = gsap.timeline({
    repeat: -1,
    repeatDelay: 1,
    defaults: {
      duration: duration,
      ease: "none",
    },
    scrollTrigger: {
      trigger: container,
      start: "top bottom",
      end: "bottom top",
      toggleActions: "play pause play pause",
    },
  });

  const zIndexArray = [200, 300, 400, 100]; // z-index logic for each item

  bg.forEach((item, index) => {
    // Apply z-index logic
    tl.set(
      [bgWrap(index), bgWrap(index + 1), bgWrap(index + 2), bgWrap(index + 3)],
      { zIndex: (index) => zIndexArray[index] },
    );
    // Move seocnd one in to view and at the same time the thrid one
    tl.to(bgWrap(index + 1), { xPercent: `-=${move}` });
    tl.to(bgWrap(index + 2), { xPercent: `-=${move}` }, "<");
    // With a delay move the first one.
    tl.to(bgWrap(index), { xPercent: `-=${move}` }, `-=${duration / 2}`);
    // Instanly move the the last one to its postion
    tl.set(bgWrap(index + 3), { xPercent: move * 2 });
    // Add a smalll pause between slides
    tl.add(() => {}, `+=${duration * 2}`);
  });
});
// END Animation ELEMENT -------------------------------------//

/* learn the GreenSock Animation Platform (GSAP 3) https://courses.snorkl.tv/courses/gsap-3-express */
// END ⭐ Kenmerken --------------//
